@use "../../styles/variables.scss" as *;

.footer {
  bottom: 0;
  padding: {
    left: 40px;
    right: 40px;
  }
  max-width: 1440px;
  margin: 0 auto;
  display: block;
  width: auto;

  &__content {
    color: $offWhite;
    font-size: 0.8rem;

    a {
      color: $offWhite;
    }
  }
}

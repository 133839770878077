/***************************************************/
/*                   Colors                        */
/***************************************************/
$black: #1C1C1C;
$white: #FFFFFF;
$offWhite: #DADDD8;

$bug: #92BC2C;
$dark: #595761;
$dragon: #0C69C8; /* Gradient if I want to get saucy: #6898F8 — #B084B4 — #F87070 */
$electric: #EDD53E;
$fairy: #EC8CE5;
$fighting: #CE4265;
$fire: #FB9B51;
$flying: #90A7DA;
$ghost: #516AAC;
$grass: #5FBC51;
$ground: #DC7545;
$ice: #70CCBD;
$normal: #9298A4;
$poison: #A864C7;
$psychic: #F66F71;
$rock: #C5B489;
$steel: #52869D;
$water: #4A90DD;

/***************************************************/
/*                  Breakpoints                    */
/***************************************************/
$tablet: 768px;

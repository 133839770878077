@use '../../styles/variables.scss' as *;

.pokemon-types {
  display: flex;
  flex-direction: column;
  margin: {
    top: 48px;
  }

  @media (min-width: $tablet) {
    flex-direction: row;
  }
}
